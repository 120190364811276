<template>
  <div class="">
    <a-modal v-model="visible"  title="提交融资需求" ok-text="提交" cancel-text="取消" @ok="hideModal" @cancel="noModal()" >
      <div class="applicationForm">
        <a-form-model  ref="form" :model="form" :rules="rules" label-width="auto"  :label-col="{ span:7 }"  :wrapper-col="{ span: 17}">
          <a-form-model-item  >
            <div class="zy">
              <span style="color: #FF9A02">注意：</span>
              <span style="color: #FF9A02">上传计划书有助于金融机构深入了解您的融资需求和进展计划，您可以根据您的情况，决定是否上传商业计划书</span>
            </div>
          </a-form-model-item>
          <a-form-model-item label="产品名称" >
            <span style="color: #333333" v-text="moneyApplyName"></span>
          </a-form-model-item>
          <a-form-model-item label="项目名称" prop="projectName" >
            <a-input size="large" style="font-size: 14px" v-model="form.projectName" placeholder="请输入项目名称"/>
          </a-form-model-item>
          <a-form-model-item label="联系人"  prop="linkman">
            <a-input size="large" style="font-size: 14px" v-model="form.linkman" placeholder="请输入联系人"/>
          </a-form-model-item>
          <a-form-model-item label="联系方式"  prop="phone">
            <a-input size="large" style="font-size: 14px" v-model="form.phone" placeholder="请输入联系方式"/>
          </a-form-model-item>
          <a-form-model-item class="aaa"  prop="projectDescription">
            <div style="float: left;width: 177px;color: #333333"><span style="color: red">*&nbsp;</span>项目整体描述（项目简介，所在行业/产业，项目前景预算，已有基础）：</div>
            <a-textarea  :rows="5"  v-model="form.projectDescription" placeholder="请输入项目描述"/>
          </a-form-model-item>
          <a-form-model-item  class="aaa" prop="financeDescription">
            <div style="float: left;width: 177px;color: #333333"><span style="color: red">*&nbsp;</span>融资要求描述（融资规模，要求说明）：</div>
            <a-textarea  :rows="4" v-model="form.financeDescription" placeholder="请输入融资要求描述"/>
          </a-form-model-item>
<!--          //prop="businessPlan"-->
          <a-form-model-item class="aaa" prop="companyProfile">
            <div style="float: left;width: 177px;color: #333333"><span style="color: red">*&nbsp;</span>公司情况介绍，管理团队，生产，销售，科研能力：</div>
            <a-textarea  :rows="4" v-model="form.companyProfile" placeholder="请输入公司情况"/>
          </a-form-model-item>
          <a-form-model-item label="商业计划书" >
            <a-upload
                :multiple="false"
                :file-list="fileList"
                :customRequest="customRequest"
                @change="handleChange"
            >
              <div style="height: 44px;width:108px;" v-if="fileList.length>0"></div>
              <a-button v-if="fileList.length<=0" style="float: right;height: 44px">
                <a-icon type="upload"/>
                上传
              </a-button>
              <div style="height: 44px;width:300px;float: left;padding:15px 0 0 0px" v-if="fileList.length===0">
                <div style="padding-top:40px;padding-left:10px;color: #ff9a02">文件大小不能超过10M。</div>
              </div>
<!--              <div class="a-upload-F" v-text="fileList.length>0?fileList[0].name:''"></div>-->
            </a-upload>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {ossUpload} from "../../api/uploader";
import {addMoneyApply} from "../../api/company/look_money";
export default {
  name: 'moneyApply',
  components: {},
  props: {
    moneyApplyName:String,
    moneyApplyId:Number,
    visible:Boolean
  },
  watch:{
  },
  data() {
    return {
      fList: [],
      fileList:[],
      form: {
        id:null,
        productId:null,
        productName:null,
        projectName:null,
        linkman:null,
        phone:null,
        projectDescription:null,
        financeDescription:null,
        companyProfile:null,
        businessPlan:null

      },
      rules: {
        productName: [{required: true, message: '请输入产品名称', trigger: 'blur'}],
        projectName: [{required: true, message: '请输入项目名称', trigger: 'blur'}],
        linkman: [{required: true, message: '请输入联系人姓名', trigger: 'blur'}],
        phone: [{required: true, message: '请输入联系人电话', trigger: 'blur'},
          { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号！' ,trigger: 'blur'}],
        projectDescription: [{required: true, message: '请输入项目描述', trigger: 'blur'}],
        financeDescription: [{required: true, message: '请输入融资要求描述', trigger: 'blur'}],
        companyProfile: [{required: true, message: '请输入公司情况介绍', trigger: 'blur'}],
        businessPlan: [{required: true, message: '请上传商业企划书', trigger: 'change'}],
      }
    }
  },
  methods: {
    customRequest(data) {
      if(data.file.size/1048576 < 10) {
        const formData = new FormData();
        formData.append("pic", data.file)
        ossUpload(formData).then(res => {
          this.fList.push(res.data.url)
        })
      }
    },
    handleChange({file,fileList}) {
      if(file.size/1048576 < 10){
      fileList = fileList.slice(-2);
      fileList = fileList.map(file => {
        file.status = 'done'
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList
      }else {
        this.$message.success("文件过大")
      }
    },
    hideModal(e) {
      //console.log(this.fList,e,"==============")
      //console.log(this.fileList,e)
      this.$refs.form.validate( async valid =>{
        if (valid) {
          this.form.productId=this.moneyApplyId;
          if (this.fList !== null && this.fList.length !== 0) {
            this.form.businessPlan = this.fList.join(',')
          }
          //console.log(this.form.businessPlan,"123123132")
          this.form.productName=this.moneyApplyName
          addMoneyApply(this.form).then(()=>{
            this.$message.success("提交成功")
            this.visible = false;
            this.$parent.visible=false;
            // this.$parent.showFormB();
            //  this.$emit('showFormB');
          })
        }
      })
    },
    noModal(){
      //  this.$emit('showFormB');
      // this.$parent.showFormB();
      this.$parent.visible=false;
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.ant-modal-root {
  .applicationForm {
    background: #FFFFFF;
    .ant-input,.col-teble{
      //text-align: center;
      line-height: 42px;
     /* height: 40px;*/
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      border-radius:0 0 0 0;
    }
    .col-teble{
      background-color: #1FABB4;
      border-bottom: 0px solid #E1E1E1;
    }
    .col-teble-last{
      background-color:#FFFFFF;
      border-bottom: 1px solid #E1E1E1;
      border-right: 1px solid #E1E1E1;
    }
    .col-teble-last1{
      background-color: #1FABB4;
      border-bottom: 0px solid #E1E1E1;
    }
    .left-input{
      border-right: 1px solid #E1E1E1;
    }
    /*    .ant-input:last-child{
          border-right: 1px solid #E1E1E1;
        }*/
    .ant-form-item {
      margin-bottom: 10px;
    }
  }
}
</style>
<style lang="scss">
.aaa{
  .ant-form-explain{
    margin-left: 175px;
  }
  .ant-form-item-control-wrapper{
    width: 600px;
    .ant-input{
      width: 423px;
      line-height: 20px !important;
    }
  }
}
.ant-modal-root{
  .ant-modal-content {
    width: 650px;
    /*height: 475px;*/
    margin-top: 0px;
    padding-bottom: 20px;
    background: #FFFFFF;
    .a-upload-F{
  padding-top: 10px;
  padding-left: 10px;
  width: 300px;
  height: 46px;
  border: 1px solid #E1E1E1;
}
    .ant-upload-list-text{
      float: left;
      padding-top: 4px;
      padding-left: 10px;
      width: 300px;
      height: 46px;
      border: 1px solid #E1E1E1;
      position: relative;
      top: -58px;
      .ant-upload-list-item-info{
        background-color: #FFFFFF;
      }
    }
    .ant-upload{
      width: 418px;
    }
    .ant-modal-header {
      /*width: 650px;*/
      height: 59px;
      background: #075FEA;
      text-align: center;

      .ant-modal-title {
        font-size: 22px;
        font-family: Hiragino Sans GB;
        color: #FFFFFF;
      }
    }

    .ant-modal-close {
      color: #e0ebfd;
    }
  }

  .ant-modal-body {
    padding-bottom: 0px;
  }

  .ant-modal-footer {
    border-top-width: 0px;

    .ant-btn {
      font-size: 18px;
      width: 119px;
      height: 42px;
      color: #075FEA;
    }

    .ant-btn-primary {
      margin-left: 30px;
      margin-right: 170px;
      background: #075FEA;
      color: #FFFFFF;
    }
  }

  #rcDialogTitle37 {
    text-align: center !important;
  }
  .zy{
    width: 600px;
    background-color:#FFF8ED;
   // background-color: rgb(255,0,0);
  }
}

</style>
