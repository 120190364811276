import request from '@/utils/request'



export function ossUpload(data) {
    return request({
        url: '/upload/ossUpload',
        method: 'post',
        data: data,
    })
}
